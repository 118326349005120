export function toggleRequired(e, controller) {
  const el = e.currentTarget;
  const currentVal = e.currentTarget.getAttribute("aria-checked");

  if (currentVal === "false") {
    this.markStepRequired(el);
  } else {
    this.removeRequiredInput(el);
  }
}

export function markStepRequired(el) {
  const parentEl = el
    .closest("main")
    .querySelector(".step-body [data-component-type]");

  if (this.hasRequiredInput(el)) return;

  const stepIndex = parentEl.dataset.stepIndex - 1;
  const newEl = this.createRequiredInput(stepIndex);

  parentEl.append(newEl);

  this.onMutation();
  this.rebuildSurveyConfig();
}

export function hasRequiredInput(el) {
  const parentEl = el
    .closest("main")
    .querySelector(
      ".step-body [data-component-type] input[name*='[required]']",
    );

  return parentEl !== null;
}

export function createRequiredInput(stepIndex) {
  const name = `survey[survey_questions_attributes][${stepIndex}][config][required]`;
  const id = `survey_survey_questions_attributes_${stepIndex}_config_required`;
  const newEl = document.createElement("input");
  newEl.type = "hidden";
  newEl.name = name;
  newEl.id = id;
  newEl.value = true;

  return newEl;
}

export function removeRequiredInput(el) {
  const parentEl = el
    .closest("main")
    .querySelector(".step-body [data-component-type]");

  if (!this.hasRequiredInput(el)) return;

  const stepIndex = parentEl.dataset.stepIndex - 1;
  const id = `survey_survey_questions_attributes_${stepIndex}_config_required`;
  document.getElementById(id).remove();
}
