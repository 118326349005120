import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["backdrop", "dialog", "dismiss"];

  static values = {
    postDismissUrl: String,
  };

  connect() {
    console.log("Modal controller connected");
    this.backdropEnteringClass =
      this.backdropTarget.dataset.enteringClasses || "";
    this.backdropLeavingClass =
      this.backdropTarget.dataset.leavingClasses || "";

    this.dialogEnteringClass = this.dialogTarget.dataset.enteringClasses || "";
    this.dialogLeavingClass = this.dialogTarget.dataset.leavingClasses || "";

    requestAnimationFrame(() => {
      this.backdropTarget.classList.remove(
        ...this.backdropLeavingClass.split(" "),
      );
      this.backdropTarget.classList.add(
        ...this.backdropEnteringClass.split(" "),
      );
    });

    requestAnimationFrame(() => {
      this.dialogTarget.classList.remove(...this.dialogLeavingClass.split(" "));
      this.dialogTarget.classList.add(...this.dialogEnteringClass.split(" "));
    });
  }

  close(e) {
    e.preventDefault();
    const abortListener = new AbortController();

    requestAnimationFrame(() => {
      this.backdropTarget.classList.remove(
        ...this.backdropEnteringClass.split(" "),
      );
      this.backdropTarget.classList.add(
        ...this.backdropLeavingClass.split(" "),
      );
    });

    this.dialogTarget.addEventListener(
      "transitionend",
      () => {
        // Remove this event listener because otherwise multiple transitions will
        // cause this function to be called N times, and will generate a console
        // error about the user aborting a request due to the modelEl.src being
        // set multiple times. Listening for one `transitionend` is fine, because
        // they all have a single duration anyway.
        abortListener.abort();
        const modalEl = document.querySelector("#modal");

        if (this.hasPostDismissUrlValue && this.postDismissUrlValue !== "") {
          modalEl.src = "";
          Turbo.visit(this.postDismissUrlValue);
        } else {
          const href = this.dismissTarget.href;
          modalEl.src = href;
        }
      },
      { signal: abortListener.signal },
    );

    requestAnimationFrame(() => {
      this.dialogTarget.classList.remove(
        ...this.dialogEnteringClass.split(" "),
      );
      this.dialogTarget.classList.add(...this.dialogLeavingClass.split(" "));
    });
  }
}
