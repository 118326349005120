import { Controller as BaseController } from "@hotwired/stimulus";

export default class Controller extends BaseController {
  static targets = ["toggleable"];
  static values = { toggledOn: Boolean };

  connect() {
    this.toggledOnValue = this.element.getAttribute(
      "data-value-initial-toggle-state",
    );
    this.toggledOnBgValue = this.element.getAttribute(
      "data-value-toggle-on-bg",
    );
    this.toggledOffBgValue = this.element.getAttribute(
      "data-value-toggle-off-bg",
    );

    const isToggledOn =
      this.toggledOnValue ||
      this.element.getAttribute("aria-checked") === "true";
    if (isToggledOn) {
      this.on();
    } else {
      this.off();
    }
  }

  toggle(event) {
    event.preventDefault();
    this.toggledOnValue = !this.toggledOnValue;
  }

  on() {
    this.toggledOnValue = true;
  }

  off() {
    this.toggledOnValue = false;
  }

  toggledOnValueChanged() {
    if (this.toggledOnValue) {
      this.element.classList.remove(this.toggledOffBgValue);
      this.element.classList.add(this.toggledOnBgValue);
    } else {
      this.element.classList.add(this.toggledOffBgValue);
      this.element.classList.remove(this.toggledOnBgValue);
    }

    this.toggleableTargets.forEach((target) => {
      const toggleOnClass = target
        .getAttribute("data-value-toggle-on-classes")
        .split(" ");
      const toggleOffClass = target
        .getAttribute("data-value-toggle-off-classes")
        .split(" ");

      if (this.toggledOnValue) {
        target.classList.remove(...toggleOffClass);
        target.classList.add(...toggleOnClass);
      } else {
        target.classList.add(...toggleOffClass);
        target.classList.remove(...toggleOnClass);
      }
    });

    this.element.setAttribute("aria-checked", this.toggledOnValue);
  }
}
