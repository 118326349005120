import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "surveyName",
    "surveyDescription",
    "summaryName",
    "summaryDescription",
    "summaryQuestions",
  ];

  connect() {
    console.log("[SurveyManager:Summary] --> connected");
    this.updateAll();
  }

  updateAll() {
    this.summaryNameTarget.innerHTML = this.surveyNameTarget.value;

    this.updateQuestionsSummary();
  }

  updateNameSummary(e) {
    this.summaryNameTarget.innerHTML = e.target.value;
  }

  updateDescriptionSummary(e) {
    this.summaryDescriptionTarget.innerHTML = e.target.value;
  }

  updateQuestionsSummary() {
    const questionsEl = document
      .querySelector("#steps")
      .querySelectorAll('input[id$="question"]:not([type="hidden"])');

    const summaryQuestionList = this.summaryQuestionsTarget.querySelector("ol");
    summaryQuestionList.innerHTML = "";

    questionsEl.forEach((el) => {
      const li = document.createElement("li");
      li.innerHTML = el.value;

      summaryQuestionList.append(li);
    });
  }
}
