import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  static values = {
    targetElement: String,
    removeClass: String,
  };

  connect() {
    this.checkForm();
  }

  checkForm() {
    for (let i = 0; i < this.inputTargets.length; i++) {
      this.inputTargets[i].addEventListener("input", () => this.takeAction());
    }
  }

  takeAction() {
    if (this.formComplete()) {
      const targetEl = document.getElementsByClassName(
        this.targetElementValue,
      )[0];
      targetEl.classList.remove(this.removeClassValue);
    }
  }

  formComplete() {
    for (let i = 0; i < this.inputTargets.length; i++) {
      if (this.inputTargets[i].value == "") {
        return false;
      }
    }
    return true;
  }
}
