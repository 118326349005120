export function toggleAllowOtherAnswer(e) {
  const el = e.currentTarget;
  const currentVal = e.currentTarget.getAttribute("aria-checked");

  if (currentVal === "false") {
    this.markStepHasOtherAnswer(el);
  } else {
    this.removeOtherAnswerInput(el);
  }
}

export function markStepHasOtherAnswer(el) {
  const parentEl = el
    .closest("main")
    .querySelector(".step-body [data-component-type]");

  if (this.hasOtherAnswerInput(el)) return;

  const stepIndex = parentEl.dataset.stepIndex - 1;
  const newEl = this.createOtherAnswerInput(stepIndex);

  parentEl.append(newEl);

  this.onMutation();
  this.rebuildSurveyConfig();
}

export function hasOtherAnswerInput(el) {
  const parentEl = el
    .closest("main")
    .querySelector(
      ".step-body [data-component-type] input[name*='[other_answer]']",
    );

  return parentEl !== null;
}

export function createOtherAnswerInput(stepIndex) {
  const name = `survey[survey_questions_attributes][${stepIndex}][config][other_answer]`;
  const id = `survey_survey_questions_attributes_${stepIndex}_config_other_answer`;
  const newEl = document.createElement("input");
  newEl.type = "hidden";
  newEl.name = name;
  newEl.id = id;
  newEl.value = true;

  return newEl;
}

export function removeOtherAnswerInput(el) {
  const parentEl = el
    .closest("main")
    .querySelector(".step-body [data-component-type]");

  if (!this.hasOtherAnswerInput(el)) return;

  const stepIndex = parentEl.dataset.stepIndex - 1;
  const id = `survey_survey_questions_attributes_${stepIndex}_config_other_answer`;
  document.getElementById(id).remove();
}
