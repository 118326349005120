import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  static targets = [
    "firstNameField",
    "lastNameField",
    "emailField",
    "passwordField",
    "passwordConfirmationField",
    "tosField",
    "signupWarning",
    "submitButton",
  ];

  connect() {}

  validateSignupForm() {
    if (
      this.firstNameFieldTarget.value != "" &&
      this.lastNameFieldTarget.value != "" &&
      this.emailFieldTarget.value != "" &&
      this.passwordFieldTarget.value != "" &&
      this.passwordConfirmationFieldTarget.value != "" &&
      this.passwordFieldTarget.value ===
        this.passwordConfirmationFieldTarget.value &&
      this.tosFieldTarget.checked
    ) {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }

  async checkEmail(e) {
    const emailFieldValue = e.currentTarget.value;

    if (emailFieldValue === "") return;

    const host = window.location.protocol + "//" + window.location.host;

    const response = await post(`${host}/waitlist/check_email`, {
      contentType: "application/json",
      responseKind: "json",
      body: { waitlist_email: { email: emailFieldValue } },
    });

    if (response.ok) {
      const respObj = await response.json;

      if (!respObj.allowed) {
        this.signupWarningTarget.classList.remove("hidden");

        setTimeout(() => {
          this.signupWarningTarget.classList.remove("opacity-0");
          this.signupWarningTarget.classList.add("opacity-100");
        }, 10);
      } else {
        this.signupWarningTarget.classList.remove("opacity-100");
        this.signupWarningTarget.classList.add("hidden", "opacity-0");
      }
    }
  }
}
