import { Controller as BaseController } from "@hotwired/stimulus";

export default class Controller extends BaseController {
  static targets = ["list"];

  static values = {
    minItems: Number,
    maxItems: Number,
    templateId: String,
  };

  get listItemsContainer() {
    return this.element.querySelector("ol");
  }

  get addNewButtonEl() {
    return this.element.querySelector(".addNewItemButton");
  }

  get listItems() {
    return this.listTarget.querySelectorAll("li");
  }

  get listItemsCount() {
    return this.listItems.length;
  }

  get deletePossible() {
    return this.listItemsCount > (this.minItemsValue || 1);
  }

  get additionPossible() {
    return (
      this.listItemsCount < (this.maxItemsValue || Number.MAX_SAFE_INTEGER)
    );
  }

  get listItemValues() {
    return this.listItems.map((li) => li.value);
  }

  connect() {
    this.observeChildren();
    this.onListMutate();
  }

  disconnect() {
    this.observer.disconnect();
  }

  observeChildren() {
    this.observer = new MutationObserver((mutationsList) => {
      this.onListMutate();
    });

    this.observer.observe(this.listItemsContainer, { childList: true });
  }

  onListMutate() {
    this.listItems.forEach((el) => {
      if (this.deletePossible) {
        el.dataset.isDeletable = "true";
      } else {
        el.dataset.isDeletable = "false";
      }
    });

    if (this.additionPossible) {
      this.element.dataset.canAddItems = "true";
      this.addNewButtonEl.disabled = false;
    } else {
      this.element.dataset.canAddItems = "false";
      this.addNewButtonEl.disabled = true;
    }
  }

  addNew(evt) {
    evt.preventDefault();

    if (this.listItemsCount >= this.maxItemsValue) {
      return;
    }

    const tmpl = document.getElementById(this.templateIdValue);
    const tmplInstance = tmpl.content.cloneNode(true);

    if (
      [
        "checkboxes_input_step_row_template",
        "radiobutton_input_step_row_template",
      ].includes(this.templateIdValue)
    ) {
      const inputEl = tmplInstance.querySelector("input");
      inputEl.value = `Sample option ${this.listItemsCount + 1}`;
    }

    this.listTarget.appendChild(tmplInstance);
  }
}
