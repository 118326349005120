import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  onKey() {
    this.element.value = this.element.value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*?)\..*/g, "$1")
      .replace(/^0[^.]/, "0");
  }
}
