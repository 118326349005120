import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.siblingContentEl = this.element.nextElementSibling;
    this.parentEl = this.element.closest("main").querySelector("div");
  }

  updateSelection(e) {
    const selectedComponentType = e.currentTarget.dataset.componentType;
    this.createElementFromTemplate(selectedComponentType);

    const requiredEl = this.element
      .closest("main")
      .querySelector(".required-question button");

    const randomizeEl = this.element
      .closest("main")
      .querySelector(".randomize-options button");

    const otherAnswerEl = this.element
      .closest("main")
      .querySelector(".allow-other-answer button");

    if (requiredEl.getAttribute("aria-checked") === "true") {
      requiredEl.click();
    }

    if (randomizeEl.getAttribute("aria-checked") === "true") {
      randomizeEl.click();
    }

    if (otherAnswerEl.getAttribute("aria-checked") === "true") {
      otherAnswerEl.click();
    }
  }

  createElementFromTemplate(id) {
    const camelToSnakeCase = (str) =>
      str.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();

    const templateId = `${camelToSnakeCase(id)}_template`;

    const tmpl = document.getElementById(templateId);
    const templInstance = tmpl.content.cloneNode(true);

    const existingTargetEl = this.element
      .closest("main")
      .querySelector(".step-body");
    existingTargetEl.replaceChildren(templInstance);
  }
}
