export function toggleSkipWelcomeMessage(e, controller) {
  const el = e.currentTarget;
  const currentVal = e.currentTarget.getAttribute("aria-checked");

  if (currentVal === "false") {
    this.removeSkipWelcomeMessageInput(el);
  } else {
    this.markStepSkipWelcomeMessage(el);
    this.updateWidgetPreviewStep(2);
  }
}

export function markStepSkipWelcomeMessage(el) {
  const parentEl = el
    .closest("main")
    .querySelector(".step-body [data-component-type]");

  if (this.hasSkipWelcomeMessageInput(el)) return;

  const stepIndex = parentEl.dataset.stepIndex - 1;
  const newEl = this.createSkipWelcomeMessageInput(stepIndex);

  parentEl.append(newEl);

  this.onMutation();
  this.rebuildSurveyConfig();
}

export function hasSkipWelcomeMessageInput(el) {
  const parentEl = el
    .closest("main")
    .querySelector(".step-body [data-component-type] input[name*='[skip]']");

  return parentEl !== null;
}

export function createSkipWelcomeMessageInput(stepIndex) {
  const name = `survey[survey_questions_attributes][${stepIndex}][config][skip]`;
  const id = `survey_survey_questions_attributes_${stepIndex}_config_skip`;
  const newEl = document.createElement("input");
  newEl.type = "hidden";
  newEl.name = name;
  newEl.id = id;
  newEl.value = true;

  const inputEl = document.querySelector('textarea[id*="config_message"]');
  inputEl.disabled = true;
  document.querySelector("#welcome_message_form_group").classList.add("hidden");
  document
    .querySelector("#welcome_message_not_used")
    .classList.remove("hidden");

  return newEl;
}

export function removeSkipWelcomeMessageInput(el) {
  const parentEl = el
    .closest("main")
    .querySelector(".step-body [data-component-type]");

  if (!this.hasSkipWelcomeMessageInput(el)) return;

  const stepIndex = parentEl.dataset.stepIndex - 1;
  const id = `survey_survey_questions_attributes_${stepIndex}_config_skip`;
  document.getElementById(id).remove();

  const inputEl = document.querySelector('textarea[id*="config_message"]');
  inputEl.disabled = false;
  document
    .querySelector("#welcome_message_form_group")
    .classList.remove("hidden");
  document.querySelector("#welcome_message_not_used").classList.add("hidden");

  this.onMutation();
  this.rebuildSurveyConfig();
}
