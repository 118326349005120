import { Controller } from "@hotwired/stimulus";
import { navigator } from "@hotwired/turbo";
import { v4 as uuidv4 } from "uuid";
import { IFrameHelper } from "../../common/iframe";

import * as ToggleMethods from "./main_form/toggle_required";
import * as ToggleOther from "./main_form/toggle_other";
import * as ToggleRandomize from "./main_form/toggle_randomize";
import * as ToggleSkipWelcomeMessage from "./main_form/toggle_skip_welcome_message";

const PREVIEW_CHANNEL = "preview";

export default class extends Controller {
  static targets = ["form", "proxyFormSubmit"];

  get stepItemsContainer() {
    return document.getElementById("steps");
  }

  get stepItems() {
    return document.querySelectorAll(
      "#welcome_step section, #steps section, #final_step section",
    );
  }

  initialize() {
    this.onMutation();
  }

  connect() {
    console.log(`[SurveyBuilder:MainForm]`, "Connected...");

    [
      ToggleMethods,
      ToggleOther,
      ToggleRandomize,
      ToggleSkipWelcomeMessage,
    ].forEach((helper) => {
      Object.keys(helper).forEach((methodName) => {
        this[methodName] = helper[methodName].bind(this);
      });
    });

    this.observeChildren();
    this.onMutation();
    this.updateWidgetPreviewStep(1);

    window.addEventListener("message", this.listenForIframeEvents.bind(this));
  }

  disconnect() {
    this.observer.disconnect();
    window.removeEventListener("message", this.listenForIframeEvents);
  }

  listenForIframeEvents(e) {
    if (!IFrameHelper.isVocSurveyWidgetMessage(PREVIEW_CHANNEL, e.data)) {
      return;
    }

    const message = IFrameHelper.getMessagePayload(PREVIEW_CHANNEL, e.data);

    if (message.event === "loaded") {
      this.onMutation();
    }

    if (message.event === "widget.step-update") {
      this.updateWidgetPreviewStep(message.currentStep + 1);
    }
  }

  observeChildren() {
    this.observer = new MutationObserver(this.onMutation.bind(this));

    this.observer.observe(this.stepItemsContainer, {
      childList: true,
      subtree: true,
    });
  }

  dragStart() {
    this.element.dataset.isDragging = true;
    console.log(`-----> DRAG START: ${this.element.dataset.isDragging} `);
  }

  dragEnd() {
    this.element.dataset.isDragging = false;
    console.log(`-----> DRAG END: ${this.element.dataset.isDragging} `);
  }

  onMutation() {
    console.log(`MUTATION --> DRAGGING: `, this.element.dataset.isDragging);
    if (this.element.dataset.isDragging === "true") return;

    this.updateOrderIndexes();
    this.updateInputIds();
    this.updateDraggables();
    this.rebuildSurveyConfig();
  }

  submitForm() {
    this.element.dataset.unsavedChangesChanged = false;
    navigator.submitForm(this.formTarget);
  }

  updateWidget() {
    IFrameHelper.sendMessageToIFrame(PREVIEW_CHANNEL, "config.update", {
      payload: this.payload,
    });
  }

  updateDraggables() {
    const totalStepItems = this.stepItems.length;

    this.stepItems.forEach((el, index) => {
      const stepType = el.dataset.stepType;
      const canDrag = el.dataset.canDrag === "true";

      if (stepType === "question" && totalStepItems > 2 && canDrag) {
        el.draggable = true;
      }
    });
  }

  updateOrderIndexes() {
    this.stepItems.forEach((el, index) => {
      const stepIndex = index + 1;
      el.dataset.stepIndex = stepIndex;

      const componentEl = el.querySelector(".step-body [data-component-type]");
      el.dataset.componentType = componentEl.dataset.componentType;
      componentEl.dataset.stepIndex = stepIndex;
      el.querySelector(".step-header").dataset.stepIndex = stepIndex - 1;
    });
  }

  updateInputIds() {
    console.log("[SurveyManager:MainForm] Rebuilding input IDs and names...");

    this.stepItems.forEach((el, index) => {
      const componentEl = el.querySelector(".step-body [data-component-type]");
      const componentType = componentEl.dataset.componentType;
      const inputs = componentEl.querySelectorAll("input, select");

      const renameNode = (input, val, regex = /(.*?)(\d+|NEW)(.*)/) => {
        const id = input.id;
        const name = input.name;
        const idMatch = id.match(regex);
        const nameMatch = name.match(regex);

        input.id = idMatch[1] + val + idMatch[3];
        input.name = nameMatch[1] + val + nameMatch[3];
      };

      const renameSubNode = (input, parentIndex, childIndex) => {
        // survey[survey_questions_attributes][NEW][config][option][1]
        // survey_survey_questions_attributes_NEW_config_option_1

        const id = input.id;
        const name = input.name;

        const parentIndexNewMatch = /(.*?)(\d+|NEW)(.+)/;
        const parentInMatch = id.match(parentIndexNewMatch);
        const parentNameMatch = name.match(parentIndexNewMatch);

        input.id = parentInMatch[1] + parentIndex + parentInMatch[3];
        input.name = parentNameMatch[1] + parentIndex + parentNameMatch[3];

        const childIndexNewMatch = /(.*?)(\d+)(]?)$/;
        const childInMatch = input.id.match(childIndexNewMatch);
        const childNameMatch = input.name.match(childIndexNewMatch);

        input.id = childInMatch[1] + childIndex + childInMatch[3];
        input.name = childNameMatch[1] + childIndex + childNameMatch[3];
      };

      inputs.forEach((input) => {
        renameNode(input, index);

        if (input.name.match(/order/)) {
          input.value = index;
        }

        if (
          ["CheckboxesInputStep", "RadioButtonInputStep"].includes(
            componentType,
          )
        ) {
          const optionInputs = componentEl.querySelectorAll("ol li input");

          optionInputs.forEach((input, subIndex) => {
            console.log("Renaming subnode...");
            renameSubNode(input, index, subIndex);
          });
        }
      });
    });
  }

  rebuildSurveyConfig() {
    console.log("[SurveyManager:MainForm] Rebuilding survey config");
    const payload = [];

    this.stepItems.forEach((el, index) => {
      const componentEl = el.querySelector(".step-body [data-component-type]");

      const component = {
        component: componentEl.dataset.componentType,
        order: componentEl.dataset.stepIndex,
        props: {
          question_id: uuidv4(),
          required: false,
        },
      };

      const inputVal = componentEl.querySelector(
        'textarea[name*="[message]"], input[name*="[message]"], input[name*="[question]"]',
      ).value;

      const requiredVal = componentEl.querySelector(
        'input[name*="[required]"]',
      );

      const skipWelcomeVal = componentEl.querySelector('input[name*="[skip]"]');
      const welcomeNextButtonText = componentEl.querySelector(
        'input[name*="[button_text]"]',
      );

      if (
        ["WelcomeStep", "FinalStep"].includes(componentEl.dataset.componentType)
      ) {
        component.props.message = inputVal;
        component.props.required = true;

        if (componentEl.dataset.componentType === "WelcomeStep") {
          component.props.skip = skipWelcomeVal !== null;
          component.props.button_text = welcomeNextButtonText.value;
        }
      } else {
        component.props.question = inputVal;
        component.props.required = requiredVal !== null;
      }

      if (
        ["RadioButtonInputStep", "CheckboxesInputStep"].includes(
          componentEl.dataset.componentType,
        )
      ) {
        const options = [];

        const inputEls = componentEl.querySelectorAll("ol input");
        [...inputEls].forEach((input) => {
          options.push(input.value);
        });

        const otherAnswerVal =
          componentEl.querySelector('input[name*="[other_answer]"]')?.value ===
          "true";

        const randomizeVal =
          componentEl.querySelector('input[name*="[randomize]"]')?.value ===
          "true";

        component.props.other_answer = otherAnswerVal;
        component.props.randomize = randomizeVal;
        component.props.options = options;
      }

      if (componentEl.dataset.componentType === "StarRatingInputStep") {
        const [inputEl] = componentEl.querySelectorAll("select option:checked");
        component.props.stars = parseInt(inputEl.value);
      }

      payload.push(component);

      this.payload = payload;
    });

    const surveyType = this.getSurveyType();

    IFrameHelper.sendMessageToIFrame(PREVIEW_CHANNEL, "config.update", {
      payload: {
        components: this.payload,
        surveyType,
      },
    });

    IFrameHelper.sendGlobalMessage(PREVIEW_CHANNEL, "config.update", {
      payload: {
        components: this.payload,
        surveyType,
      },
    });

    const configUpdateEvent = new CustomEvent("config.updated");
    window.dispatchEvent(configUpdateEvent);
  }

  getSurveyType() {
    let surveyType = "popup";
    const popupInputEl = document.querySelector(
      "#survey_config_survey_type_popup",
    );
    const lightboxInputEl = document.querySelector(
      "#survey_config_survey_type_lightbox",
    );

    if (popupInputEl.checked) {
      surveyType = "popup";
    } else if (lightboxInputEl.checked) {
      surveyType = "modal";
    } else {
      surveyType = "page";
    }

    return surveyType;
  }

  questions() {
    return [
      ...document.getElementById("steps").getElementsByTagName("section"),
    ].filter((q) => q.dataset.stepType === "question");
  }

  lastQuestion() {
    return this.questions().slice(-1).pop();
  }

  addNewQuestion(e) {
    e.preventDefault();

    const tmpl = document.getElementById("step_row_template");

    const textInputTmpl = document.getElementById("text_input_step_template");
    const newRow = document.importNode(tmpl.content, true);
    const currentQuestionCount = this.questions().length;

    newRow.firstElementChild.dataset.stepIndex = currentQuestionCount + 1;
    newRow.firstElementChild.dataset.stepType = "question";

    const contentEl = [
      ...[...newRow.children].pop().getElementsByTagName("main"),
    ].pop();

    const newContent = document.importNode(textInputTmpl.content, true);
    contentEl.querySelector(".step-body").appendChild(newContent);

    const stepOptionsTempl = document.getElementById("step_options_template");
    const selectorTmplInstance = stepOptionsTempl.content.cloneNode(true);
    contentEl
      .querySelector(".step-body")
      .parentElement.appendChild(selectorTmplInstance);

    this.lastQuestion().parentNode.insertBefore(
      newRow,
      this.lastQuestion().nextSibling,
    );
    this.onMutation();
  }

  deleteQuestion(e) {
    e.preventDefault();

    if (this.questions().length === 1) {
      alert("You can't delete the only question in the survey.");
      return;
    }

    console.log(`Deleting question`);
    const targetStep = e.currentTarget.closest("section").dataset.stepIndex;
    const targetQuestionStep = this.questions()
      .filter((q) => q.dataset.stepIndex === targetStep)
      .pop();

    targetQuestionStep.remove();
    this.onMutation();
  }

  updateSurveyType(evt) {
    const type = evt.currentTarget.value;
    console.log(`[SurveyManager]`, `Updating survey type to ${type}`);
    this.resetFocus();
    this.onMutation();
  }

  focusStep(evt) {
    const stepIndex = evt.currentTarget.dataset.stepIndex;
    if (this.hasSkipWelcomeMessageInput(this.element) && stepIndex === "1") {
      return;
    }

    IFrameHelper.sendMessageToIFrame(PREVIEW_CHANNEL, "config.update.focus", {
      payload: { step: stepIndex },
    });
    this.updateWidgetPreviewStep(stepIndex);
  }

  updateWidgetPreviewStep(stepIndex) {
    const el = document.querySelector("#widget-preview h2.step-info");
    let stepCount = this.stepItems.length;
    let step = stepIndex;

    if (this.hasSkipWelcomeMessageInput(this.element)) {
      stepCount -= 1;
      step = stepIndex - 1;
    }
    el.innerText = `Widget Preview (Step ${step} of ${stepCount})`;
  }

  resetFocus() {
    const surveyType = this.getSurveyType();

    let targetStartingStep = 1;

    if (this.hasSkipWelcomeMessageInput(this.element)) {
      targetStartingStep = 2;
    }

    IFrameHelper.sendMessageToIFrame(PREVIEW_CHANNEL, "config.update.focus", {
      payload: {
        step: targetStartingStep,
        surveyType,
      },
    });
    document
      .querySelector("iframe#vocsurveys_main_iframe_preview")
      .classList.add("open");
    this.updateWidgetPreviewStep(targetStartingStep);
  }
}
