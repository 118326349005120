import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  get togglers() {
    return this.context.bindingObserver.bindings
      .filter((binding) => binding.action.methodName == "toggle")
      .map((binding) => binding.action.element);
  }

  get openedTogglers() {
    return this.togglers.filter((toggler) => this.isOpened(toggler));
  }

  get contents() {
    return this.scope.findAllElements("[data-accordion-id]");
  }

  connect() {
    this.togglers.forEach((toggler) => {
      let content = this.#findContent(toggler);
      if (this.isOpened(toggler)) {
        this.show(toggler, content, false);
      } else {
        this.hide(toggler, content, false);
      }
    });

    this.#initMutationObserver();
  }

  disconnect() {
    this.mutationObserver?.disconnect();
    this.mutationObserver = null;
  }

  next(e) {
    e.preventDefault();
    const toggleId = `content_${e.target.dataset.nextForValue}`;
    const nextToggleId = `content_${
      parseInt(e.target.dataset.nextForValue) + 1
    }`;

    const parentToggle = this.context.bindingObserver.bindings
      .filter((binding) => binding.eventTarget.dataset.toggleFor === toggleId)
      .shift().action.element;

    const nextToggle = this.context.bindingObserver.bindings
      .filter(
        (binding) => binding.eventTarget.dataset.toggleFor === nextToggleId,
      )
      .shift().action.element;

    this.close(parentToggle);
    this.open(nextToggle);
  }

  toggle(e) {
    e.preventDefault();

    this.togglers.forEach((toggler) => {
      if (toggler.contains(e.target)) {
        if (this.isOpened(toggler)) {
          this.close(toggler);
        } else {
          this.open(toggler);
        }
      } else {
        this.close(toggler);
      }
    });
  }

  open(toggler) {
    let content = this.#findContent(toggler);
    this.show(toggler, content);
  }

  close(toggler) {
    let content = this.#findContent(toggler);
    this.hide(toggler, content);
  }

  show(toggler, content, transition = true) {
    setTimeout(() => {
      content.style.height = content.scrollHeight + "px";
    });

    this.toggleClass(toggler, content, true);
    toggler.dataset.accordionState = "opened";
  }

  hide(toggler, content, transition = true) {
    setTimeout(() => {
      content.style.height = "0px";
    });

    this.toggleClass(toggler, content, false);
    toggler.dataset.accordionState = "closed";
  }

  toggleClass(toggler, content, opened) {
    if (opened) {
      content.style.height = content.scrollHeight + "px";
    } else {
      content.style.height = "";
    }
  }

  isOpened(toggler) {
    return toggler.dataset.accordionState == "opened";
  }

  #initMutationObserver() {
    const config = { childList: true, subtree: true };
    const observer = new MutationObserver(() => this.updateAccordion());
    this.mutationObserver = observer.observe(this.element, config);
  }

  updateAccordion() {
    this.togglers.forEach((toggler) => {
      const content = this.#findContent(toggler);

      if (toggler.dataset.accordionState === "opened") {
        const builderCol = document.getElementById("builder-col");
        const builderColScrollPos = builderCol.scrollTop;
        content.style.height = "auto";
        content.style.height = content.scrollHeight + "px";
        builderCol.scroll({ top: builderColScrollPos });
      }
    });
  }

  #findContent(toggler) {
    let id = this.#getID(toggler);
    return this.scope.findElement(`[data-accordion-id="${id}"]`);
  }

  #getID(toggler) {
    return toggler.dataset.toggleFor;
  }
}
