import { Controller } from "@hotwired/stimulus";
import { patch } from "@rails/request.js";

export default class extends Controller {
  static targets = ["button", "toggle"];

  async toggle() {
    const checked =
      this.buttonTarget.getAttribute("aria-checked") == "true" ? true : false;

    if (checked) {
      this.buttonTarget.classList.remove("bg-primary");
      this.toggleTarget.classList.remove("translate-x-5");
      this.buttonTarget.classList.add("bg-gray-200");
      this.toggleTarget.classList.add("translate-x-0");
      this.buttonTarget.setAttribute("aria-checked", false);
    } else {
      this.buttonTarget.classList.remove("bg-gray-200");
      this.toggleTarget.classList.remove("translate-x-0");
      this.buttonTarget.classList.add("bg-primary");
      this.toggleTarget.classList.add("translate-x-5");
      this.buttonTarget.setAttribute("aria-checked", true);
    }
  }
}
