export function toggleRandomizeOptions(e) {
  const el = e.currentTarget;
  const currentVal = e.currentTarget.getAttribute("aria-checked");

  if (currentVal === "false") {
    this.markThisStepHasRandomize(el);
  } else {
    this.removeRandomizeInput(el);
  }
}

export function markThisStepHasRandomize(el) {
  const parentEl = el
    .closest("main")
    .querySelector(".step-body [data-component-type]");

  if (this.hasRandomizeInput(el)) return;

  const stepIndex = parentEl.dataset.stepIndex - 1;
  const newEl = this.createRandomizeInput(stepIndex);

  parentEl.append(newEl);

  this.onMutation();
  this.rebuildSurveyConfig();
}

export function hasRandomizeInput(el) {
  const parentEl = el
    .closest("main")
    .querySelector(
      ".step-body [data-component-type] input[name*='[randomize]']",
    );

  return parentEl !== null;
}

export function createRandomizeInput(stepIndex) {
  const name = `survey[survey_questions_attributes][${stepIndex}][config][randomize]`;
  const id = `survey_survey_questions_attributes_${stepIndex}_config_randomize`;
  const newEl = document.createElement("input");
  newEl.type = "hidden";
  newEl.name = name;
  newEl.id = id;
  newEl.value = true;

  return newEl;
}

export function removeRandomizeInput(el) {
  const parentEl = el
    .closest("main")
    .querySelector(".step-body [data-component-type]");

  if (!this.hasRandomizeInput(el)) return;

  const stepIndex = parentEl.dataset.stepIndex - 1;
  const id = `survey_survey_questions_attributes_${stepIndex}_config_randomize`;
  document.getElementById(id).remove();
}
