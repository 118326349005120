import "@hotwired/turbo-rails";
import { Application } from "@hotwired/stimulus";
import * as ActiveStorage from "@rails/activestorage";
import { registerControllers } from "./import_stimulus_controllers";
import "./turbo_custom_actions";
import "./import_svg_icons_for_prod";
import.meta.glob("../../app/assets/images/*", { eager: true });
import.meta.glob("../../app/components/**/index.js", { eager: true });

const application = Application.start();

let controllers = import.meta.glob("./**/*_controller.js", { eager: true });
registerControllers(application, controllers);

controllers = import.meta.glob("../../app/components/**/*_controller.js", {
  eager: true,
});
registerControllers(application, controllers);

controllers = import.meta.glob("../stimulus/**/*_controller.js", {
  eager: true,
});
registerControllers(application, controllers);

ActiveStorage.start();

const events = [
  "turbo:fetch-request-error",
  "turbo:frame-missing",
  "turbo:frame-load",
  "turbo:frame-render",
  "turbo:before-frame-render",
  "turbo:load",
  "turbo:render",
  "turbo:before-stream-render",
  "turbo:before-render",
  "turbo:before-cache",
  "turbo:submit-end",
  "turbo:before-fetch-response",
  "turbo:before-fetch-request",
  "turbo:submit-start",
  "turbo:visit",
  "turbo:before-visit",
  "turbo:click",
];

events.forEach((e) => {
  addEventListener(e, () => {
    console.log(e);
  });
});

export const StimulusMainApplication = application;
