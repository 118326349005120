import { Controller as BaseController } from "@hotwired/stimulus";

export default class Controller extends BaseController {
  static targets = ["timeRemaining"];

  connect() {
    const timeoutSeconds = parseInt(this.data.get("timeout"));

    if (!this.isPreview) {
      // Display with transition
      setTimeout(() => {
        this.element.classList.remove("hidden");

        this.element.classList.add(
          "transform",
          "ease-out",
          "duration-300",
          "transition",
          "translate-y-2",
          "opacity-0",
          "sm:translate-y-0",
          "sm:translate-x-2"
        );

        // Trigger transition
        setTimeout(() => {
          this.element.classList.add("translate-y-0", "opacity-100", "sm:translate-x-0");
        }, 100);

        if (this.hasTimeRemainingTarget) {
          this.timeRemainingTarget.style.animation = "c-notification-decrease-width linear " + timeoutSeconds + "s";
        }
      }, 250);

      // Auto-hide
      setTimeout(() => {
        this.close();
      }, timeoutSeconds * 1000 + 500);
    }
  }

  close() {
    // Remove with transition
    this.element.classList.remove(
      "transform",
      "ease-out",
      "duration-300",
      "translate-y-2",
      "opacity-0",
      "opacity-100",
      "sm:translate-y-0",
      "sm:translate-x-2",
      "translate-y-0",
      "sm:translate-x-0"
    );
    this.element.classList.add("transition", "ease-in", "duration-100");

    // Trigger transition
    setTimeout(() => {
      this.element.classList.add("opacity-0", "translate-x-10");
    }, 100);

    // Remove element after transition
    setTimeout(() => {
      this.element.remove();
    }, 300);
  }

  get isPreview() {
    return document.documentElement.hasAttribute("data-turbolinks-preview");
  }
}
