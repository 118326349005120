import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["label", "input"];

  connect() {}

  setSelection(e) {
    e.preventDefault();

    const labelText =
      e.currentTarget.querySelector(".select-label-value")?.innerText ||
      e.currentTarget.innerText;

    const id = e.currentTarget.id;

    this.labelTarget.querySelector(".label-text").innerText = labelText;

    if (this.hasInputTarget) {
      this.inputTarget.value = id;
    }
  }
}
