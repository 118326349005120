import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  includeRuleCount() {
    return document.getElementById("include-rules").querySelectorAll(".rule")
      .length;
  }

  excludeRuleCount() {
    return document.getElementById("exclude-rules").querySelectorAll(".rule")
      .length;
  }

  fixupTemplateId(keyEl, type, index) {
    keyEl.name = keyEl.name
      .replace("TEMPLATED", type)
      .replace(/\[(\d)\]/, `[${index}]`);
    keyEl.id = keyEl.id
      .replace("TEMPLATED", type)
      .replace(/_\d_/, `_${index}_`);
  }

  addIncludeRow(e) {
    e.preventDefault();
    console.log("adding include row");
    const newRuleTmpl = document.getElementById("page_matches_template_row");
    const newRow = document.importNode(newRuleTmpl.content, true);

    const keyEl = newRow.querySelector('input[type="text"]');
    this.fixupTemplateId(keyEl, "include", this.includeRuleCount());

    const valEl = newRow.querySelector('input[type="hidden"]');
    this.fixupTemplateId(valEl, "include", this.includeRuleCount());

    const includeRules = document.getElementById("include-rules");
    includeRules.appendChild(newRow);
  }

  addExcludeRow(e) {
    e.preventDefault();
    console.log("adding exclude row");
    const newRuleTmpl = document.getElementById("page_matches_template_row");
    const newRow = document.importNode(newRuleTmpl.content, true);

    const keyEl = newRow.querySelector('input[type="text"]');
    this.fixupTemplateId(keyEl, "exclude", this.excludeRuleCount());

    const valEl = newRow.querySelector('input[type="hidden"]');
    this.fixupTemplateId(valEl, "exclude", this.excludeRuleCount());

    const excludeRules = document.getElementById("exclude-rules");
    excludeRules.appendChild(newRow);
  }

  deleteRow(e) {
    const renameNode = (input, val, regex = /(.*?)(\d+|NEW)(.*)/) => {
      const id = input.id;
      const name = input.name;
      const idMatch = id.match(regex);
      const nameMatch = name.match(regex);

      input.id = idMatch[1] + val + idMatch[3];
      input.name = nameMatch[1] + val + nameMatch[3];
    };

    const parentEl = e.currentTarget.closest(".rule").parentElement;
    e.currentTarget.closest(".rule").remove();

    const remainingRules = [...parentEl.querySelectorAll(".rule")];

    remainingRules.forEach((remainingRule, index) => {
      const children = [...remainingRule.querySelectorAll("input")];

      children.forEach((el) => {
        renameNode(el, index);
      });
    });
  }
}
