import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  onFocus() {
    if (this.element.dataset.input_dirty) return;
    this.element.value = "";
  }

  onInput() {
    this.element.dataset.input_dirty = true;
  }
}
