import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  onFormSubmit() {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "form_submit",
      formId: "beta-waitlist",
    });
  }
}
