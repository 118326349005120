import { Controller as BaseController } from "@hotwired/stimulus";

export default class Controller extends BaseController {
  delete(evt) {
    evt.preventDefault();

    if(this.element.dataset.isDeletable === 'true') {
      this.element.remove();
    }
  }
}
