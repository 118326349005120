import { Controller as BaseController } from "@hotwired/stimulus";

export default class Controller extends BaseController {
  connect() {
    this.placeholder = null;
  }

  dragstart(event) {
    const windowEvent = new CustomEvent("survey-builder-question-drag-start");
    window.dispatchEvent(windowEvent);

    event.dataTransfer.setData(
      "application/drag-key",
      event.target.getAttribute("data-step-index"),
    );
    event.dataTransfer.effectAllowed = "move";

    this.createPlaceholder(event.target);
  }

  dragover(event) {
    event.preventDefault();
    const dropTarget = event.target.closest("section[draggable=true]");
    if (dropTarget) {
      const rect = dropTarget.getBoundingClientRect();
      const relY = event.clientY - rect.top;
      if (relY < rect.height / 2) {
        dropTarget.before(this.placeholder);
      } else {
        dropTarget.after(this.placeholder);
      }
    }
    return true;
  }

  dragenter(event) {
    event.preventDefault();
  }

  drop(event) {
    const windowEvent = new CustomEvent("survey-builder-question-drag-end");
    window.dispatchEvent(windowEvent);

    event.preventDefault();
    const data = event.dataTransfer.getData("application/drag-key");
    const draggedItem = this.element.querySelector(
      `[data-step-index='${data}']`,
    );
    this.placeholder.replaceWith(draggedItem);
  }

  dragend(event) {
    if (this.placeholder) {
      this.placeholder.remove();
    }
  }

  createPlaceholder(targetElement) {
    this.placeholder = document.createElement("div");
    this.placeholder.classList.add("drag-placeholder");
    this.placeholder.style.width = `${targetElement.offsetWidth}px`;
    this.placeholder.style.height = `${targetElement.offsetHeight}px`;
    this.placeholder.style.marginBottom = "16px";
    this.placeholder.style.padding = "0";
    this.placeholder.style.border = "2px dashed black";
    this.placeholder.style.boxSizing = "border-box";
    this.placeholder.style.backgroundColor = "rgba(0,0,0,0.1)";
  }
}
